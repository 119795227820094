@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');

* {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: rgb(15, 15, 15);
  background-image: url('./assets/wavesslim.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  height: 100vh;
}
